<template>
  <div class="dashboard__container--body" v-if="(userProfile  && userProfile.id)">
    <div class="dashboard__container--body--col shiftCard">
      
      <div class="whiteBack">
        <h1>Welcome Aboard</h1>
        <p>We're excited to help you find your next career or gig work.</p>
        <div class="mt-1">
          <router-link :to="{ name: 'findGroups' }" class="darkLink">
            <div class="listItem flex justify-space-between"><span>Search Careers</span><i class="fa-regular fa-arrow-right fa-2x"></i></div>
          </router-link>
          <router-link :to="{ name: 'availableShifts' }" class="darkLink">
            <div class="listItem flex justify-space-between"><span>Find Gig Work</span><i class="fa-regular fa-arrow-right fa-2x"></i></div>
          </router-link>
          <router-link :to="{ name: 'accountDetails' }" class="darkLink">
            <div class="listItem flex justify-space-between"><span>Manage Your Account</span><i class="fa-regular fa-arrow-right fa-2x"></i></div>
          </router-link>
          <!-- <router-link :to="{ name: 'payrollHome' }" class="darkLink">
            <div class="listItem flex justify-space-between"><span>Payroll Setup</span><i class="fa-regular fa-arrow-right fa-2x"></i></div>
          </router-link> -->
          <router-link :to="{ name: 'accountSkills' }" class="darkLink">
            <div class="listItem flex justify-space-between"><span>Add Your Job Skills</span><i class="fa-regular fa-arrow-right fa-2x"></i></div>
          </router-link>
          <router-link :to="{ name: 'accountResume' }" class="darkLink">
            <div class="listItem flex justify-space-between" style="border:none;"><span>Upload Your Resume</span><i class="fa-regular fa-arrow-right fa-2x"></i></div>
          </router-link>
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped>
  .listItem {
    border-bottom: 1px solid #ddd;
    padding: 1rem 0 0.75rem 0;
  }
  .listItem .fa-2x {
    font-size: 1.8rem;
  }
  .shiftCard {
    margin: 2rem auto 6rem;
  }
</style>

<script>
import { mapState } from 'vuex'
import router from '@/router'

export default {
  name: 'accountWelcome',
  computed: {
    ...mapState(['userProfile', 'currentUser']),
  },
}
</script>